<template>
  <div>
    <!-- 操作按钮区域 -->
    <div class="margin-b-15">
      <a-button
        @click="handleAdd"
        type="primary"
        icon="plus-square"
      >新增</a-button>
      <a-button
        v-if="selectedRowKeys.length > 0"
        type="danger"
        ghost
        icon="delete"
        @click="batchDel"
      >批量删除</a-button>
      <a-switch
        checkedChildren="是"
        unCheckedChildren="否"
        :defaultChecked="false"
        @click="isSwitch"
      />
    </div>
    <!-- table区域-begin -->
    <div
      class="ant-alert ant-alert-info"
      style="margin-bottom: 16px;"
    >
      <i class="anticon anticon-info-circle ant-alert-icon"></i>已选择&nbsp;
      <a style="font-weight: 600">{{ selectedRowKeys.length }}</a>&nbsp;项&nbsp;&nbsp;
      <a
        class="margin-l-8"
        @click="onClearSelected"
      >清空</a>
    </div>
    <div v-if="dataSource && dataSource.length">
      <a-table
        :columns="columns"
        size="middle"
        :pagination="false"
        :dataSource="dataSource"
        :loading="loading"
        :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
        rowKey="id"
        :defaultExpandAllRows="isNo"
      >
        <span
          slot="action"
          slot-scope="text, record"
        >
          <a @click="handleEdit(record)">编辑</a>
          <a-divider
            style="margin:0px"
            type="vertical"
          />
          <a-dropdown>
            <a class="ant-dropdown-link">
              更多
              <a-icon type="down" />
            </a>
            <a-menu slot="overlay">
              <a-menu-item>
                <a
                  href="javascript:;"
                  @click="handleDetail(record)"
                >详情</a>
              </a-menu-item>
              <a-menu-item>
                <a
                  href="javascript:;"
                  @click="handleAddSub(record)"
                >添加子菜单</a>
              </a-menu-item>
              <a-menu-item>
                <a-popconfirm
                  title="确定删除吗?"
                  @confirm="() => handleDelete(record.id)"
                >
                  <a>删除</a>
                </a-popconfirm>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </span>
      </a-table>
    </div>
    <!-- table区域-end -->
    <permission-modal
      ref="modalForm"
      @ok="modalFormOk"
    ></permission-modal>
  </div>
</template>

<script>
import PermissionModal from './permission-modal'
import api from './api'
import { isEmpty } from '@/common/tools'
import { deletes } from '@/common/request'

const columns = [
  {
    title: '菜单名称',
    dataIndex: 'name',
    key: 'name'
  }, {
    title: '菜单类型',
    dataIndex: 'menuType',
    key: 'menuType',
    customRender: function (text) {
      if (text === 0) {
        return '菜单'
      } else if (text === 1) {
        return '菜单'
      } else if (text === 2) {
        return '按钮/权限'
      } else {
        return text
      }
    }
  }, /* {
    title: '权限编码',
    dataIndex: 'perms',
    key: 'permissionCode',
  }, */{
    title: 'icon',
    dataIndex: 'icon',
    key: 'icon'
  },
  {
    title: '组件',
    dataIndex: 'component',
    key: 'component',
    scopedSlots: { customRender: 'component' }
  },
  {
    title: '路径',
    dataIndex: 'url',
    key: 'url',
    scopedSlots: { customRender: 'url' }
  },
  {
    title: '排序',
    dataIndex: 'sortNo',
    key: 'sortNo'
  },
  {
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
    width: 150
  }
]

export default {
  props: {
    tabKey: {
      type: String
    }
  },
  type: {},
  name: 'PermissionList',
  components: {
    PermissionModal
  },
  data () {
    return {
      isNo: false,
      selectedRowKeys: [],
      dataSource: [],
      report: {
      },
      description: '这是菜单管理页面',
      // 表头
      columns: columns,
      loading: false,
      url: {
        list: '/sys/permission/list',
        delete: '/sys/permission/delete',
        deleteBatch: '/sys/permission/deleteBatch'
      }
    }
  },
  methods: {
    loadData () {
      this.dataSource = []
      api.getMenuTree().then((res) => {
        this.dataSource = res.data
      })
    },
    isSwitch (checked, event) {
      if (checked === true) {
        this.isNo = true
        this.loadData()
      } else {
        this.isNo = false
        this.loadData()
      }
    },
    /* // 打开数据规则编辑
    handleDataRule (record) {
      this.$refs.PermissionDataRuleList.edit(record)
    }, */
    handleAddSub (record) {
      this.$refs.modalForm.title = '添加子菜单'
      this.$refs.modalForm.localMenuType = 1
      this.$refs.modalForm.disableSubmit = false
      this.$refs.modalForm.edit({ status: '1', permsType: '1', route: true, parentId: record.id, menuGroup: this.tabKey })
      this.$refs.modalForm.XqDisabled = false
    },
    // 新增
    handleAdd: function () {
      if (isEmpty(this.tabKey)) {
        this.tabKey = 1
      }
      this.$refs.modalForm.add(this.tabKey)
      this.$refs.modalForm.title = '新增'
      this.$refs.modalForm.disableSubmit = false
      this.$refs.modalForm.XqDisabled = false
    },
    // 编辑
    handleEdit: function (record) {
      this.$refs.modalForm.edit(record)
      this.$refs.modalForm.title = '编辑'
      this.$refs.modalForm.disableSubmit = false
      this.$refs.modalForm.XqDisabled = false
    },
    // 详情
    handleDetail: function (record) {
      this.$refs.modalForm.edit(record)
      this.$refs.modalForm.title = '详情'
      this.$refs.modalForm.disableSubmit = true
      this.$refs.modalForm.XqDisabled = true
    },
    // 多选删除
    batchDel: function () {
      if (!this.url.deleteBatch) {
        this.$message.error('请设置url.deleteBatch属性!')
        return
      }
      if (this.selectedRowKeys.length <= 0) {
        this.$message.warning('请选择一条记录！')
      } else {
        var ids = ''
        for (var a = 0; a < this.selectedRowKeys.length; a++) {
          ids += this.selectedRowKeys[a] + ','
        }
        var that = this
        this.$confirm({
          title: '确认删除',
          content: '是否删除选中数据?',
          cancelText: '取消',
          okText: '确定',
          onOk: function () {
            deletes(that.url.deleteBatch, { ids: ids }).then((res) => {
              that.$message.success(res.message)
              that.loadData()
              that.onClearSelected()
            })
          }
        })
      }
    },
    // 单项删除
    handleDelete: function (id) {
      if (!this.url.delete) {
        this.$message.error('请设置url.delete属性!')
      }
      var that = this
      deletes(that.url.delete, { id: id }).then((res) => {
        that.$message.success(res.message)
        that.loadData()
      })
    },
    onClearSelected () {
      this.selectedRowKeys = []
    },
    modalFormOk () {
      // 新增/修改 成功时，重载列表
      this.loadData()
    },
    onSelectChange (selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys
    }
  },
  created () {
    this.loadData()
  }
}
</script>
